import React, { useState, useEffect } from 'react';
import './nav.css';
import { AiOutlineHome } from 'react-icons/ai';
import { CiUser } from 'react-icons/ci';
import { FiFolderMinus } from 'react-icons/fi';
import { BiMessageSquareDots } from 'react-icons/bi';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.getElementById('about');
      /*const competencesSection = document.getElementById('competences');
      const formationSection = document.getElementById('formation');*/
      const experienceSection = document.getElementById('experience');
      const projetsSection = document.getElementById('projets');
      /*const temoignagesSection = document.getElementById('temoignages');*/
      const contactSection = document.getElementById('contact');
  
      const scrollPosition = window.scrollY;
  
      if (scrollPosition < aboutSection.offsetTop) {
        setActiveNav('#home');
      } else if (
        scrollPosition >= aboutSection.offsetTop &&
        scrollPosition < experienceSection.offsetTop
      ) {
        setActiveNav('#about');
      } else if (
        scrollPosition >= experienceSection.offsetTop &&
        scrollPosition < projetsSection.offsetTop
      ) {
        setActiveNav('#about');
      } else if (
        scrollPosition >= projetsSection.offsetTop &&
        scrollPosition < contactSection.offsetTop
      ) {
        setActiveNav('#projets');
      /*  scrollPosition < temoignagesSection.offsetTop
      ) {
        setActiveNav('#projets');
     } else if (
        scrollPosition >= temoignagesSection.offsetTop &&
        scrollPosition < contactSection.offsetTop
      ) {
      setActiveNav('#temoignages');*/
      } else if (scrollPosition >= contactSection.offsetTop) {
        setActiveNav('#contact');
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    // Nettoie l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  
  
  return (
    <nav>
      <a href="#home" onClick={() => setActiveNav('#home')} className={activeNav === '#home' ? 'active' : ''}>
        <AiOutlineHome />
      </a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}>
        <CiUser />
      </a>
      <a href="#projets" onClick={() => setActiveNav('#projets')} className={activeNav === '#projets' ? 'active' : ''}>
        <FiFolderMinus />
      </a>
      {/*<a href="#temoignages" onClick={() => setActiveNav('#temoignages')} className={activeNav === '#temoignages' ? 'active' : ''}>
        <HiOutlineUserGroup />
      </a>*/}
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
        <BiMessageSquareDots />
      </a>
    </nav>
  );
};

export default Nav;
