import React from 'react'
import './competences.css'
import {BsFillPatchCheckFill} from 'react-icons/bs'
const Competences= () => {
  return (
    <section id="competences">
      <h5>Concernant</h5>
      <h2>Mes compétences</h2>

      <div className="container competence_container">
        <div className="experience_uidesign">
          <h3>UI Design</h3>
          <div className="competence_content">
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                  <h4>Figma</h4>
                  <small className='text-light'>Avancé</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>Adobe XD</h4>
                <small className='text-light'>Avancé</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>Photoshop</h4>
                <small className='text-light'>Débutant</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>Canva</h4>
                <small className='text-light'>Avancé</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>Illustrator</h4>
                <small className='text-light'>Débutant</small>
              </div>
            </article>
          </div>
          </div>

          <div className="experience_uidesign">
          <h3>Frontend Dev </h3>
          <div className="competence_content">
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Avancé</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Avancé</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Intermédiaire</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>React</h4>
                <small className='text-light'>Débutant</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>Angular</h4>
                <small className='text-light'>Intermédiaire</small>
              </div>
            </article>
          </div>
          </div>

          <div className="experience_uidesign">
          <h3>Backend Dev </h3>
          <div className="competence_content">
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>Java</h4>
                <small className='text-light'>Avancé</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>PHP</h4>
                <small className='text-light'>Avancé</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>SQL</h4>
                <small className='text-light'>Intermédiaire</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>Python</h4>
                <small className='text-light'>Intermédiaire</small>
              </div>
            </article>
            <article className="competence_detail">
              <BsFillPatchCheckFill className='competence_details-icons'/>
              <div>
                <h4>C</h4>
                <small className='text-light'>Avancé</small>
              </div>
            </article>
          </div>
          </div>
      </div>
    </section>
  )
}

export default Competences