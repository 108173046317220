import React from 'react'
import CV from '../../assets/cv_AXEL_DEFO.pdf'
import { FaDownload } from 'react-icons/fa';

const CTA = () => {
  return (
    <div className="cta">
        <a href={CV} download className='btn'>Mon CV &ensp;<FaDownload size="20px"/></a>
        <a href="#contact" className='btn btn-primary'>Me contacter</a>
    </div>
  )
}

export default CTA