import React from 'react'
import './formation.css'
import {BiCheck} from 'react-icons/bi'

const Formation = () => {
  return (
    <section id="formation">
      <h5>Concernant</h5>
      <h2>Ma formation</h2>

      <div className="container formation_container">

        <article className="formation">
          <div className="formation_head">
            <h3>1- Baccalauréat C</h3>
          </div>

          <ul className="formation_list">
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Mathématiques : Arithmétique, Géométrie.</p>
            </li>
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Informatique : Programmation, Algorithmique, Bases de données, Réseaux, Systèmes d'exploitation.  </p>
            </li>
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Physique : Mécanique, Électromagnétisme, Optique.  </p>
            </li>
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Chimie : Structure de la matière, Réactions chimiques, Chimie organique.  </p>
            </li>
            
            
            
           
          </ul>
        </article>

        
        <article className="formation">
          <div className="formation_head">
            <h3>2- Classes préparatoires</h3>
          </div>

          <ul className="formation_list">
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Informatique : Programmation (C, Java & web), Algorithmique, Bases de données, Réseaux, SE,...  </p>
            </li>
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Électronique : Circuits électriques, Électronique analogique et numérique,... </p>
            </li>
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Physique : Mécanique, Électromagnétisme, Optique, Thermodynamique,...  </p>
            </li>
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Anglais : Compréhension écrite et orale, Expression écrite et orale, Vocabulaire technique,...  </p>
            </li>
         
          </ul>
        </article>

        <article className="formation">
          <div className="formation_head">
            <h3>3- Bac+3 école d'ingénieur</h3>
          </div>

          <ul className="formation_list">
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Programmation  : COO & POO, Structures de données, Algorithmique, Web, Framework (Angular),...  </p>
            </li>
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Technologie de l'information : Électronique, Architecture des ordinateurs, des réseaux, Logique combinatoire et séquentielle,...  </p>
         </li>
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Conduite de projet : Travail d'équipe, gestion de projets, Team Building, Projets transversaux,...  </p>
            </li>
           
            <li>
              <BiCheck className='formation_list-icon' />
              <p>Application des Mathématiques : Introduction à l'intelligence artificielle, programmation scientifique,...  </p>
            </li>
          </ul>
        </article>

      </div>
    </section>
  )
}

export default Formation