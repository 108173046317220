import React from 'react'
import './projets.css'
//import IM1 from '../../assets/projets/IM2.png'
import IM2 from '../../assets/projets/IM2.png'
import IM3 from '../../assets/projets/IM3.png'
import IM4 from '../../assets/projets/IM4.png'
import IM5 from '../../assets/projets/IM5.png'
import IM6 from '../../assets/projets/IM6.png'
import IM7 from '../../assets/projets/IM7.png'
import IM8 from '../../assets/projets/IM8.png'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { FreeMode, Pagination } from "swiper";

const data = [
  
  {
    id: 8,
    image: IM8,
    title: 'Projet Personnel: Conception et développement de mon site web personnel (2023) - Figma, React JS',
    github: 'https://www.figma.com/proto/fhDMyb6jDpOyaJGaa6q7yf/Portfolio?page-id=0%3A1&type=design&node-id=1-3&viewport=499%2C228%2C0.54&scaling=scale-down&starting-point-node-id=1%3A3',
    lire: 'https://docs.google.com/document/d/1Kcj4bGtAOfC4RcydtCoAuQHMazauLF6bDmE2TnCwcOU/edit?usp=sharing'
  },
  {
    id: 7,
    image: IM7,
    title: 'Projet transversal 3A: Les 24H du Code (2023) - Figma',
    github: 'https://www.figma.com/proto/s6rrzv8tDznUCc9hokPgI9/24H-du-Code?page-id=0%3A1&type=design&node-id=1-2&viewport=344%2C343%2C0.07&scaling=scale-down&starting-point-node-id=1%3A2',
    lire: 'https://docs.google.com/document/d/1KVldGPoRCzL66TFGc0rmdmFzoKXs0niAmJ1YoLquVDo/edit?usp=sharing'
  },
  {
    id: 6,
    image: IM6,
    title: 'Projet structurant: Analyse, conception et développement d’une application de gestion (Mai 2022) - Java',
    github: 'https://github.com/axeldefo/PrepaVoyage',
    lire: 'https://docs.google.com/document/d/125sQhZkRd0ySIIwq19l67KDZq0bxel1mK-JA3bhv3IA/edit?usp=sharing'
  },
  {
    id: 5,
    image: IM5,
    title: 'Projet Personnel: Réalisation d’un site web pour fast food (2022) - HTML & CSS',
    github: 'https://github.com/axeldefo/King-Food',
    lire: 'https://docs.google.com/document/d/1rYgKv71g0jO8mLGAdpUbbgWCl9jUhAvZbhwl8-28VEk/edit?usp=sharing'
  },
  {
    id: 4,
    image: IM4,
    title: 'Projet Framework (Angular) :Site Web de e-commerce (vente d’ordinateurs)(2023) - Figma & Angular',
    github: 'https://github.com/axeldefo/Projet-Angular-MyPC-',
    lire: 'https://docs.google.com/document/d/1YTjmSs-PLC0aa2lwTOsgg-rdvdnRhTEHvYURkNvhkpI/edit?usp=sharing',
    sup: 'https://www.figma.com/proto/sAe9kXuqjRge0q2Bhp3P8P/MyPC?page-id=0%3A1&type=design&node-id=3-57&viewport=-2697%2C427%2C0.43&scaling=min-zoom&starting-point-node-id=3%3A57'
  },
  {
    id: 3,
    image: IM3,
    title: 'Projet Personnel: Conception et développement d’un site web de cours en ligne (2023) - Figma, HTML, CSS & JS',
    github: 'https://github.com/axeldefo/King-School',
    lire: 'https://docs.google.com/document/d/1M0Ucz7gtdY5S5_XQA-gSimoGet3X0oCueILXFlT7VFA/edit?usp=sharing',
    sup: 'https://www.figma.com/proto/7wsCVVERqZYsYdICho2VYq/King-School?page-id=0%3A1&type=design&node-id=2-3&viewport=92%2C86%2C0.09&scaling=min-zoom&starting-point-node-id=2%3A3&show-proto-sidebar=1'
    
  },{
    id: 2,
    image: IM2,
    title: "Projet informatique: Développement d’un site web de réservation de chambre d'hôtel (janvier 2023) - HMTL, CSS, JS, PHP & AJAX",
    github: 'https://github.com/axeldefo/Tour-hotel',
    lire: 'https://docs.google.com/document/d/1hHyS4frvAzcCXsJsiR0DJ-oMLMoMP-UVkAnhts9AJwE/edit?usp=sharing'
  }
  /*},
  {
    id: 1,
    image: IM1,
    title: 'Lorem dfkdjflkfdfdgdg',
    github: 'https://github.com',
    lire: 'https://github.com'
  },*/
 
]

const Projets = () => {
  const openLinks = (id, demoLink, additionalLink) => {
    if (id === 3 || id === 4) {
      window.open(demoLink, '_blank');
      window.open(additionalLink, '_blank');
    } else {
      window.open(demoLink, '_blank');
    }
  };

  return (
    <section id="projets">
      <h5>Mes Projets</h5>
      <h2>Portfolio</h2>

      <Swiper
        className="container portfolio_container"
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
      >
        {data.map(({ id, image, title, github, sup, lire }) => {
          return (
            <SwiperSlide key={id} className="portfolio_items">
              <div className="portfolio_item-image">
                <img src={image} alt={title} height={300} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio_item-cta">
                <a
                  href={github}
                  className="btn"
                  target="_blank" rel="noreferrer"
                  onClick={() => openLinks(id, github, sup)}
                >
                  {id === 3 || id === 4? 'Démo' : 'Démo'}
                </a>
                <a href={lire} className='btn btn-primary' target='_blank' rel="noreferrer">Lire</a>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Projets;
