import React from 'react'
import './contact.css'
import {MdMailOutline} from 'react-icons/md'
import {BsLinkedin} from 'react-icons/bs'
import {TbCopyright} from 'react-icons/tb'
import { useRef,  useState} from 'react';
import emailjs from 'emailjs-com';

const Contact= () => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_basxeip', 'template_w4006tn', form.current, 'nUSVQMjuyR2MgADXx')
      .then((result) => {
          console.log(result.text);
          setMessageSent(true); 
          setTimeout(() => setMessageSent(false), 2000);
      }, (error) => {
          console.log(error.text);
      });
      
      e.target.reset();
      <p>Message envoyé !</p>
  };
  return (
    <section id="contact">
      <h5>Comment</h5>
      <h2>Me contacter</h2>

      <div className="container contact_container">
        <form ref = {form} onSubmit={sendEmail}>
          <input type="text" name ="nom" placeholder='Votre nom complet' required/>
          <input type="text" name ="email" placeholder='Votre adresse mail' required/>
          <input type="text" name ="objet" placeholder="L'objet de votre message" required/>
          <textarea name="message" id="" cols="30" rows="10" placeholder='Votre message'></textarea>
          <button type='submit'className='btn btn-primary'>Envoyer un message</button>
        </form>
        <div className="contact_options">
          <article className='contact_option'>
            <MdMailOutline className='contact_icon'/>
            <h4>Email</h4>
            <h5>Axel.Defo_Mbobda.Etu@univ-lemans.fr</h5>
            <a className='a' href="mailto:Axel.Defo_Mbobda.Etu@univ-lemans.fr" target='_blank' rel="noreferrer">Envoyer un message</a>
          </article>
          <article className='contact_option'>
            <BsLinkedin className='contact_icon'/>
            <h4>Linkedin</h4>
            <h5>Axel DEFO MBOBDA</h5>
            <a className='a' href="https://www.linkedin.com/public-profile/settings?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_self_edit_contact-info%3BqaBdA1fDTza25lsIONmWaQ%3D%3D" target='_blank' rel="noreferrer">Voir mon profil</a>
          </article>
          <article className='contact_option'>
            <TbCopyright className='contact_icon'/>
            <h4>Axel D. 2022-2023</h4>
            <h5>Tous droits réservés.</h5>
            </article>
        </div>
      </div>
      {messageSent && 
        <div className="message_sent">
        <p>Message envoyé !</p>
      </div>
      }
    </section>
  )
}

export default Contact