import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './Accueil.css'
import CTA from './CTA'
import ME from '../../assets/me.png'
import Social from './Social';

const Accueil= () => {
  return (
    <header id = "home">
        <div className="container header_container">
            <h5 className='h5'>Bonjour, je suis</h5>
            <h1>Axel DEFO M.</h1>
            <h5 className="text_light">Elève ingénieur en developpement informatique,
              à la recherche d’une alternance pour 2 ans.</h5>

            <CTA/>
            <Social/>
            <div className='me'> 
                <img src={ME} alt="" width="280px" height ="300px" />
            </div>

            <a href="#contact" className='scroll_down'>
            <div className="icon-container">
                <FontAwesomeIcon icon={faChevronDown} className="custom-icon"  size="lg"/>
                <FontAwesomeIcon icon={faChevronDown} className="custom-icon" size="lg"/>
                <FontAwesomeIcon icon={faChevronDown} className="custom-icon"  size="lg"/>
                <FontAwesomeIcon icon={faChevronDown} className="custom-icon"  size="lg"/>
                <FontAwesomeIcon icon={faChevronDown} className="custom-icon"  size="lg"/>
                   
            </div>    
            </a>
        </div>
        
    </header>
  )
}

export default Accueil