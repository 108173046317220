import React from "react";
import { createRoot } from 'react-dom/client';
import App from './app';
import './index.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);


createRoot(document.getElementById('root')).render(<App/>);