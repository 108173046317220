import React from 'react'
import Moi from './components/Moi/moi'
import Nav from './components/nav/nav'
import Competences from './components/competences/competences'
import Formation from './components/formation/formation'
import Experience from './components/experience/experience'
import Contact from './components/contact/contact'
//import Temoignages from './components/temoignages/temoignages'
import Projets from './components/projets/projets'
import Accueil from './components/Accueil/Accueil'


const App = () => {
  return (
    <>
     <Accueil/>
      <Nav />
      <Moi/>
      <Competences />
      <Formation />
      <Experience/>
      <Projets />
      <Contact />

    </>
  )
}

export default App