import React from 'react'
import './moi.css'
import ME from '../../assets/proposs.png'
import {FaAward} from 'react-icons/fa'
import {FaUserGraduate} from  'react-icons/fa'
import {HiFolderOpen} from 'react-icons/hi'

const Moi = () => {

  const handleExpProClick3 = () => {
    const expeDiv = document.getElementById('experience');
    if (expeDiv) {
      window.scrollTo({
        top: expeDiv.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const handleExpProClick2 = () => {
    const expeDiv = document.getElementById('formation');
    if (expeDiv) {
      window.scrollTo({
        top: expeDiv.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const handleExpProClick1 = () => {
    const expeDiv = document.getElementById('competences');
    if (expeDiv) {
      window.scrollTo({
        top: expeDiv.offsetTop,
        behavior: 'smooth',
      });
    }
  };
  return (
    <section id="about">
      <h5>
        Savoir plus
      </h5>
      <h2>A propos de moi</h2>
      <div className="container about_container">

      <div className="about_content">
          <div className="about_cards">

            <article className="about_card" onClick={handleExpProClick1}>
            <  FaAward className = "about_icon"/>
              <h5>Mes compétences</h5> 
            </article>
            <article className="about_card" onClick={handleExpProClick2}>
            < FaUserGraduate className = "about_icon"/>
              <h5>Ma formation</h5> 
            </article>
            <article className="about_card" onClick={handleExpProClick3}>
            <  HiFolderOpen className = "about_icon"/>
              <h5>Expériences professionnelles</h5> 
            </article>
          </div>

          <p>Je suis un étudiant en ingénierie informatique en 3e année, avec une solide formation en classes préparatoires en informatique. J'ai également acquis une certaine expérience professionnelle, ce qui m'a permis de développer mes compétences en communication, résolution de problèmes informatiques et en gestion de projets. À présent, je suis à la recherche d'une alternance en développement informatique et/ou en design UI, afin de mettre en pratique mes compétences techniques et mon expérience.</p>

          <a href="#contact" className="btn btn-primary aa">Me contacter</a>

        </div>

        <div className="about_me">
          <div className="image">
            <img src={ME} alt="bref" />
          </div>
        </div>

        
      </div>
    </section>
  )
}

export default Moi