import React, { useState, useEffect } from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { MdNightlight } from 'react-icons/md';
import { WiDaySunny } from 'react-icons/wi';

const Social = () => {
  const [isNightMode, setIsNightMode] = useState(true);

  useEffect(() => {
    // Ajouter ou supprimer la classe "day-mode" sur l'élément "html"
    if (isNightMode) {
      document.documentElement.classList.remove('day-mode');
    } else {
      document.documentElement.classList.add('day-mode');
    }
  }, [isNightMode]);

  const toggleMode = () => {
    setIsNightMode(!isNightMode);
  };

  return (
    <div className="social">
      <a href="https://www.linkedin.com/in/axel-defo-mbobda" target="_blank" rel="noreferrer">
        <BsLinkedin />
      </a>
      <a href="https://github.com/axeldefo" target="_blank" rel="noreferrer">
        <FaGithub />
      </a>
      {isNightMode ? (
        <WiDaySunny onClick={toggleMode} className="mode-icon day" />
      ) : (
        <MdNightlight onClick={toggleMode} className="mode-icon night" />
      )}
    </div>
  );
};

export default Social;
