import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import './experience.css';

const Experience = () => {
  useEffect(() => {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    const chart = am4core.create('chartdiv', am4charts.XYChart);

    // Add data
    chart.data = [
      {
        y: '1',
        x: 1,
        text: 'Travailleur en usine\n[bold;color =#999;]PROLEG S.A, Bandjoun[/]\nJuin - Août 2018 & 2019',
        center: 'right',
      },
      {
        y: '2',
        x: 1,
        text: '[bold]Caissier[/]\n[bold;color =#999;]LIPANOUV, Douala[/]\nJuin - Août 2020 & 2021',
        center: 'left',
      },
      {
        y: '3',
        x: 1,
        text: 'Equipier polyvalent[/]\n[bold;color =#999; ]BURGER KING, Le Mans Bollée[/]\nMars 2023 (en cours)',
        center: 'right',
      },
    ];

    // Create axes
    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.dataFields.category = 'y';
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;
    yAxis.tooltip.disabled = true;

    const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.min = 0;
    xAxis.max = 1.99;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.labels.template.disabled = true;
    xAxis.renderer.baseGrid.disabled = true;
    xAxis.tooltip.disabled = true;

    // Create series
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.categoryY = 'y';
    series.dataFields.valueX = 'x';
    series.strokeWidth = 3;
    series.sequencedInterpolation = true;

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.setStateOnChildren = true;
    bullet.states.create('hover');
    bullet.circle.radius = 10;
    bullet.circle.states.create('hover').properties.radius = 15;

    const labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.setStateOnChildren = true;
    labelBullet.states.create('hover').properties.scale = 1.2;
    labelBullet.label.text = '{text}';
    labelBullet.label.maxWidth = 200;
    labelBullet.label.wrap = true;
    labelBullet.label.truncate = false;
    labelBullet.label.textAlign = 'middle';
    labelBullet.label.paddingLeft = 20;
    labelBullet.label.paddingRight = 20;
    labelBullet.label.fill = am4core.color('#999');
    labelBullet.label.states.create('hover').properties.fill = am4core.color('#999');

    labelBullet.label.verticalCenter = 'middle';
    labelBullet.label.propertyFields.horizontalCenter = 'center';

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;
    chart.cursor.interactive = false; 
    
    // Custom styles
    series.stroke = am4core.color('#38B6FF'); // Couleur de la ligne de la LineSeries
    bullet.circle.fill = am4core.color('#38B6FF'); // Couleur du cercle de CircleBullet
    labelBullet.label.fill = am4core.color('#38B6FF'); // Couleur du texte du LabelBullet

    // Cleanup on unmount
    return () => {
      chart.dispose();
    };
  }, []);

  return (
    <section id="experience">
      <h5>Mes expériences</h5>
      <h2>Professionnelles</h2>
      <div id="chartdiv" style={{ width: '100%', height: '80%' }} />
    </section>
  );
};

export default Experience;
